import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import getFullName from '@/storychief/utils/getFullName';

const propTypes = {
  size: PropTypes.number,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
    profile_picture: PropTypes.string.isRequired,
    verified: PropTypes.bool,
  }).isRequired,
};

const defaultProps = {
  size: 30,
};

const fragments = {
  user: gql`
    fragment UserThumb on User {
      __typename
      id
      firstname
      lastname
      email
      profile_picture
      role
      verified
    }
  `,
};

function UserThumb({ user, size }) {
  return (
    <div className="user user--thumb">
      <img
        width={size}
        height={size}
        src={user.profile_picture}
        alt={`${getFullName(user)} ${user.email ? `(${user.email})` : ''}`}
        className="img-responsive user-profile-image img-circle d-inline-block va-middle"
      />
    </div>
  );
}

UserThumb.propTypes = propTypes;
UserThumb.defaultProps = defaultProps;
UserThumb.fragments = fragments;

export default UserThumb;
